<template>
  <section class="category-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <validation-observer ref="observer" class="w-100">
      <div class="w-100">
        <b-form-group :label="$t('message.RegionUz')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.RegionUz')"
              rules="required"
          >
            <b-input v-model="form.region_uz" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.RegionRu')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.RegionRu')"
              rules="required"
          >
            <b-input v-model="form.region_ru" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.RegionEn')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.RegionEn')"
              rules="required"
          >
            <b-input v-model="form.region_en" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="success" @click="saveSubmit" >{{$t('message.Add')}}</b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { storeRegion, updateRegion, showRegion } from '@/api/region'
import { formTemplate } from '@/utils/mixins/formTemplate';
import { quillEditor }  from 'vue-quill-editor';
import vSelect from 'vue-select';

function initForm(data) {
  const get = (key, value = null) => _.get(data,key,value);

  return {
    id: get('id', null),
    parent_id: get('parent_id',null),
    region_uz: get('region_uz', null),
    region_ru: get('region_ru', null),
    region_en: get('region_en', null),
  }
}

const actions = {
  add:storeRegion,
  update: updateRegion,
  show: showRegion,
}

export default {
  name: 'Form',
  components:{
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  mixins: [
    formTemplate,
  ],
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      allCategories: [],
      allTypes: [],
    }
  },
  methods: {
    reformatData() {
      this.form = {...this.form, parent_id: this.$route.params ? this.$route.params.id : null};
      let form = this.prepareFormData(this.form);
      return form;
    },
    setForm(data) {
      this.form = initForm(data);
    },
  }
}
</script>

<style scoped>

</style>
